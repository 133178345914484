
//==========  Borders  ==========//

// build our default border style

$border-size: 2px;
$border-style: solid;
$border-color: rgba($color-primary, .05);

// combine border variables into a default
// this is used throughout our modules as a base
$border: $border-size $border-style $border-color;

// set a default border-radius
// this is used in our forms and search styling
$border-radius: 6px;
