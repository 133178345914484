
// Default link events
a {
	color: $color-primary-light;
	text-decoration: none;
	transition: all t(link) ease-out;

	&:hover {
		color: $color-secondary;
	}
}

.block-text {

	a {

		&:not([class]) {
			border-bottom: $border-size $border-style $color-secondary;
			padding-bottom: $site-padding / 8;
		}
	}
}
