
// Default .button class
.button {
    @extend %button;

    &-secondary {
        background-color: darken($color-pastel-blue, 10%);
        color: $color-primary-light;
    }

    &:hover {
        @extend %hover;
    }

    // automatically position icons when creating buttons
    .icon {
        left: calc(#{$button-padding} - #{$icon-width * 0.5});
    }


    // apply a reset for original implentation of .button class
    // (was on parent element, now on link itself)
    a {
        color: inherit;
        transition: inherit;
    }
}


// button list
// (usually seen on /site/onlinepayment page)
.buttons {
    @extend %clear-fix;

    .button {
        margin: 0 0 ($block-padding * 0.5);

        @media #{$tabletXL} {
            @include fraction(2, $block-padding);
        }
    }
}

